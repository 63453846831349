import { gameInfos } from "chinese-dos-games-data-export/lib/infos";

for (let gameInfo of Object.values(gameInfos)) {
  if ("coverImage" in gameInfo) {
    gameInfo.coverFilename = gameInfo.coverImage.filename;
    gameInfo.width = gameInfo.coverImage.width;
    gameInfo.height = gameInfo.coverImage.height;
  }
}

export default gameInfos;